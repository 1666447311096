import { select2Init } from "../functions/select2Init";
import Swal from "sweetalert2";
import Sortable from 'sortablejs'

const questionnaireForm = () => {

    const loader = window.customLoader

    $('.areas-list').each( function () {
        const sortable = Sortable.create(this, {
            filter: '.no-sort',
            onChange: function()
            {
                let sequence = this.toArray()
                $.ajax({
                    type: 'post',
                    url: Routing.generate('surveys_survey_set_sequence'),
                    data: {
                        sequence: sequence
                    }
                })
            },
            handle: '.handle',
            animation: 150,
        })
    })

    $('.questions-list').each( function () {
        const sortable = Sortable.create(this, {
            filter: '.no-sort',
            onChange: function()
            {
                let sequence = this.toArray()

                $.ajax({
                    type: 'post',
                    url: Routing.generate('surveys_survey_set_sequence_question'),
                    data: {
                        sequence: sequence
                    }
                })
            },
            handle: '.handle-question',
            animation: 150,
        })
    })

    $(document).on('submit', '#areaModal', function (e) {
        e.preventDefault();

        const parent = $(this).parents('.modal-body')
        const modal = parent.parents('.modal')
        const id = parent.data('id')
        const at = parent.data('at')

        parent.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">Trwa ładowanie...</p></div>`)

        $.ajax({
            type: 'post',
            url:  Routing.generate('surveys_survey_edit_area', {at: at, id: id}),
            data: $(this).serialize()
        }).done(d => {
            if (d.ok)
            {
                modal.modal('hide')
                $("#areas-refresh").load(location.href + " #areas-refresh")
            }
            else if (d.form)
            {
                parent.html(d.form)
            }
        })

    })

    $('#modal-area-form').on('shown.bs.modal', function (event) {

        const id = $(event.relatedTarget).data('id')
        const at = $(event.relatedTarget).data('at')
        const modal = $(this)
        const body = modal.find('.modal-body')

        body.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">Trwa ładowanie...</p></div>`)

        $.ajax({
            type: 'get',
            url: Routing.generate('surveys_survey_edit_area', {at:at, id:id})
        }).done((d) => {

            if (d.form)
            {
                body.html(d.form)
                body.attr('data-at', at)
                body.attr('data-id', id)
                select2Init('.select2')
            }
        })
    })

    $(document).on('click','.remove-area', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz usunąć obszar?',
            text: 'Spowoduje to usunięcie pytań i wszystkich danych w obszarze',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, usuń',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_survey_remove_area', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        $("#areas-refresh").load(location.href + " #areas-refresh")

                        Swal.fire({
                            icon: 'success',
                            title: 'Obszar został usunięty',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się usunąć obszaru',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano usuwanie obszaru',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(document).on('submit', '#questionModal', async function (e) {
        e.preventDefault();

        const parent = $(this).parents('.modal-body')
        const modal = parent.parents('.modal')
        const id = parent.data('id')
        const at = parent.data('at')

        parent.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">Trwa ładowanie...</p></div>`)

        $.ajax({
            type: 'post',
            url:  Routing.generate('surveys_survey_edit_question', {at: at, id: id}),
            data: $(this).serialize()
        }).done(d => {
            if (d.ok)
            {
                modal.modal('hide')
                $("#areas-refresh").load(location.href + " #areas-refresh")
            }
            else if (d.form)
            {
                parent.html(d.form)
            }
        })

    })

    $('#modal-question-form').on('shown.bs.modal', function (event) {

        const id = $(event.relatedTarget).data('id')
        const at = $(event.relatedTarget).data('at')
        const modal = $(this)
        const body = modal.find('.modal-body')

        body.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">Trwa ładowanie...</p></div>`)

        $.ajax({
            type: 'get',
            url: Routing.generate('surveys_survey_edit_question', {at:at, id:id})
        }).done((d) => {

            if (d.form)
            {
                body.html(d.form)
                body.attr('data-at', at)
                body.attr('data-id', id)
                select2Init('.select2')
            }
        })
    })

    $(document).on('click','.remove-question', function() {

        const id = $(this).data('id')
        const area = $(this).data('area')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz usunąć pytanie?',
            text: 'Spowoduje to usunięcie wszystkich danych w pytaniu',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, usuń',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('surveys_survey_remove_question', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        $("#questions-refresh-" + area ).load(location.href + " #questions-refresh-" + area)

                        Swal.fire({
                            icon: 'success',
                            title: 'Pytanie zostało usunięte',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się usunąć pytania',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano usuwanie pytania',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })

    $(() => {

        select2Init('.select2')

    })

}

window.initQuestionnaireForm = () => {
    questionnaireForm()
}