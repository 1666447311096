import {select2Init} from "../functions/select2Init";
import {dropifyInit} from "../functions/dropifyInit";

const fillAuditAnswerQuestion = () => {

    function visibleBoxes()
    {

        const selectedAnswer = $('#answer_answer option:selected'),
            photoBox = $('#photoBox'),
            commentBox = $('#commentBox')

        const photoRequired = selectedAnswer.data('photo-required'),
            commentRequired = selectedAnswer.data('comment-required')

        if (photoRequired === '' || photoRequired === 0)
        {
            photoBox.slideUp('slow')
            photoBox.find('label').attr('required', false).removeClass('required')
            photoBox.find('input').attr('required', false)
        }
        else
        {
            photoBox.slideDown('slow')

            if (photoRequired === 2)
            {

                if (!photoBox.find('input[type=file]').data('default-file'))
                {
                    photoBox.find('label').attr('required', true).addClass('required')
                    photoBox.find('input').attr('required', true)
                }
                else
                {
                    photoBox.find('label').attr('required', false).removeClass('required')
                    photoBox.find('input').attr('required', false)
                }
            }
            else
            {
                photoBox.find('label').attr('required', false).removeClass('required')
                photoBox.find('input').attr('required', false)
            }
        }
        if (commentRequired === '' || commentRequired === 0)
        {
            commentBox.slideUp('slow')
            commentBox.find('label').attr('required', false).removeClass('required')
            commentBox.find('input').attr('required', false)
        }
        else
        {
            commentBox.slideDown('slow')

            if (commentRequired === 2)
            {
                commentBox.find('label').attr('required', true).addClass('required')
                commentBox.find('input').attr('required', true)
            }
            else
            {
                commentBox.find('label').attr('required', false).removeClass('required')
                commentBox.find('input').attr('required', false)
            }
        }


        photoBox.find('label').attr('required', false).removeClass('required')
        photoBox.find('input').attr('required', false)

        commentBox.find('label').attr('required', false).removeClass('required')
        commentBox.find('input').attr('required', false)

    }

    function visiblePhotoForChecklist(input)
    {
        const holder = input.parents('.checklistItemHolder')
        const state = input.is(':checked')

        const dropifyElem = holder.find('input[data-file=dropify]')
        const inputElem = holder.find('textarea[data-checklist=comment_checkList]')

        if (dropifyElem.length !== 0)
        {
            dropifyElem.attr('required', state)
        }

        if (inputElem.length !== 0 )
        {
            inputElem.attr('required', state)
        }



    }

    $(() => {
        select2Init('[data-select=select2]', {
            minimumResultsForSearch: Infinity
        })
        dropifyInit('[data-file=dropify]')

        visibleBoxes()


        // TODO: Poprawić jakość JS (kiedyś)
        const checklistImages = $('input[data-checklist=dropify]')

        checklistImages.each(function () {
            $(this).attr('required', false)
        })
    })

    $(document).on('change', '#answer_answer', function () {
        visibleBoxes()
    })

    $(document).on('change', '*[data-checklist=checklist]', function () {
        visiblePhotoForChecklist($(this))
    })

}

window.initFillAuditAnswerQuestion = () => {
    fillAuditAnswerQuestion()
}