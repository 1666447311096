import { datatableInit } from "../functions/datatableInit";
import {select2Init} from "../functions/select2Init";

const loader = window.customLoader

const reportLocation = () => {

    $(document).on('click', '.export-location', function () {

        const url = (Routing.generate('location_export'))
        window.open(url, '_blank')
    })

    $('#search-filters').on('click', function () {
        $('.datatable').DataTable().ajax.reload()
    })

    $('#reset-filters').on('click', function () {
        $('#number').val('').change();
        $('#round').val('').change();
        $('#customer').val('').change();
        $('#date-from').val('');
        $('#date-to').val('');
        $('.datatable').DataTable().ajax.reload()
    })

    $(() => {

        datatableInit('.datatable', {
            columns: [
                {name: 'a.id'},
                {name: 'c.companyName'},
                {name: 'r.id'},
                {name: 'l.number'},
                {name: 'q.code'},
                {name: 'a.answer'},
                {name: 'points', orderable: false}
            ],
            order: [0, 'desc'],
            bServerSide: true,
            stateSave: true,
            paginate: true,
            processing: true,
            bDestroy: true,
            autoWidth: false,
            searching: false,
            lengthMenu: [25,50,100],
            ajax: {
                beforeSend: function (){
                    loader.show()
                },
                url: Routing.generate('report_report_location_ajax'),
                type: "POST",
                data: function ( d ) {
                    d.number = $('#number :selected').data('number')
                    d.round = $('#round :selected').data('round')
                    d.customer = $('#customer :selected').data('customer')
                    d.dateFrom = $('#date-from').val();
                    d.dateTo = $('#date-to').val();

                },
                complete: function (){
                    loader.hide()
                }
            },
        })

        select2Init('select[data-audit-select=audit-select2]')
    })

}

window.initReportLocation= () => {
    reportLocation()
}