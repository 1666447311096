import { datatableInit } from "../functions/datatableInit"
import Swal from "sweetalert2"
import flatpickr from "flatpickr"
import {Polish} from "flatpickr/dist/l10n/pl"
import axios from "axios"

const loader = window.customLoader

const roundList = () => {

    $(() => {

        datatableInit('.datatable')

        $('.hasTooltip').tooltip()

    })

    $(document).on('click', '.date-edit', async function (e) {
        e.preventDefault()
        const roundId = $(this).data('round')
        const start = new Date(Number($(this).data('start')) * 1000)

        let flatPickerInstance

        Swal.fire({
            icon: 'question',
            title: 'Wybierz datę zakończenia',
            input: 'text',
            inputLabel: 'Data zakończenia tury',
            allowOutsideClick: false,
            confirmButtonColor: '#25b372',
            confirmButtonText: 'Zmień datę zakończenia',
            showCancelButton: true,
            cancelButtonColor: '#ef5350',
            cancelButtonText: 'Anuluj',
            willOpen: () => {
                flatPickerInstance = flatpickr(
                    Swal.getPopup().querySelector('.swal2-input'), {
                        locale: Polish
                    }
                )
            },
            didOpen: () => {
                Swal.getConfirmButton().focus()
            },
            preConfirm: () => {
                const dateEnd = new Date(Swal.getPopup().querySelector('.swal2-input').value)

                if (Swal.getPopup().querySelector('.swal2-input').value === '')
                {
                    Swal.showValidationMessage(`Musisz wybrać datę`)
                }

                if (dateEnd <= start) {
                    Swal.showValidationMessage(`Data zakończenia musi być większa od daty rozpoczęcia`)
                }

                return {
                    dateEnd: dateEnd
                }

            }

        }).then((res) => {

            if (res.isConfirmed)
            {
                loader.show()

                const data = new FormData()

                data.append('date', res.value.dateEnd.toJSON().slice(0, 10))

                axios.post(Routing.generate('round_edit_date', {id: roundId}), data, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then(r => {
                    const data = r.data
                    let icon, title, content, reload

                    if (data.error)
                    {
                        icon = 'error'
                        title = 'Błąd'
                        content = data.error
                        reload = false
                    }
                    else
                    {
                        icon = 'success'
                        title = 'Sukces'
                        content = 'Poprawnie zmieniono datę zakończenia'
                        reload = true
                    }

                    loader.hide()

                    Swal.fire({
                        icon: icon,
                        title: title,
                        text: content,
                        allowOutsideClick: !reload
                    }).then( () => {

                        if (reload)
                        {
                            location.reload()
                        }

                    })
                }).catch( () => {
                    loader.hide()

                    Swal.fire({
                        icon: 'error',
                        title: 'Błąd',
                        text: 'Wystąpił błąd serwera'
                    }).then(null)
                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano!',
                    confirmButtonColor: '#25b372'
                }).then()
            }

        })

    })

    $(document).on('click','.remove-round', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz usunąć turę?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, usuń',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('rounds_remove', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'Tura została usunięta',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            location.reload()
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się usunąć tury',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano usuwanie tury',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })


    $(document).on('click','.edit-status-round', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz zmienić status tury?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, zmień',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                $.ajax({
                    beforeSend: function (){
                        loader.show()
                    },
                    type: 'post',
                    url: Routing.generate('rounds_edit_status', {id: id}),
                    complete: function (){
                        loader.hide()
                    }
                }).done(d => {
                    if (!d.error)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'Status tury został zmieniony',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        }).then(() => {
                            location.reload()
                        } )
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się zmienić statusu tury',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })
                    }

                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano zmianę statusu tury',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })


    $(document).on('click','.edit-display-priority-round', function() {

        const id = $(this).data('id')

        Swal.fire({
            icon: 'question',
            title: 'Czy chcesz zmienić priorytet wyświetlania tury?',
            text: 'Wprowadź nowy priorytet',
            input: 'number',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            showConfirmButton: true,
            confirmButtonText: 'Tak, zmień',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545'
        }).then(result => {
            if (result.isConfirmed)
            {
                if(result.value){
                    $.ajax({
                        beforeSend: function (){
                            loader.show()
                        },
                        type: 'post',
                        url: Routing.generate('rounds_edit_display_priority', {id: id, val: result.value}),
                        complete: function (){
                            loader.hide()
                        }
                    }).done(d => {
                        if (!d.error)
                        {
                            Swal.fire({
                                icon: 'success',
                                title: 'Priorytet wyświetlania tury został zmieniony',
                                showConfirmButton: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                confirmButtonText: 'Zamknij',
                                confirmButtonColor: '#3f51b5',
                            }).then(() => {
                                location.reload()
                            } )
                        }
                        else
                        {
                            Swal.fire({
                                icon: 'error',
                                title: 'Nie udało się zmienić priorytetu wyświetlania tury',
                                text: d.error,
                                confirmButtonText: 'Zamknij',
                                confirmButtonColor: '#3f51b5',
                            })
                        }

                    })
                }
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano zmianę priorytetu wyświetlania tury',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })

            }
        })
    })


}

window.initRoundList = () => {
    roundList()
}