import {select2Init} from "../functions/select2Init";

const scaleForm = () => {

    function disableIfNotApplicable()
    {

        $('select[data-bind="not-applicable"]').each( function () {

            const parent = $(this).parents('.option').eq(0)

            const toDisable = parent.find('*[data-bind="to-disable"]')

            if ($(this).val() == 1)
            {

                toDisable.each(function () {
                    $(this).val('').attr('required', false).attr('disabled', true)
                })

            }
            else
            {

                toDisable.each(function () {
                    $(this).attr('required', true).attr('disabled', false)
                })

            }

        })

    }

    $(() => {

        select2Init('select[data-select=select2]', {
            minimumResultsForSearch: Infinity
        })
        disableIfNotApplicable()

    })

    $(document).on('change', 'select[data-bind="not-applicable"]', () => {
        disableIfNotApplicable()
    })

    const addFormToCollection = (e) => {
        const collectionHolder = document.querySelector('.' + e.currentTarget.dataset.collectionHolderClass);

        const item = document.createElement('div');
        item.setAttribute('class','col-12 collection-item option card card-body');

        item.innerHTML = collectionHolder
            .dataset
            .prototype
            .replace(
                /__name__/g,
                collectionHolder.dataset.index
            );

        collectionHolder.appendChild(item);

        collectionHolder.dataset.index++;

        select2Init('select[data-select=select2]', {
            minimumResultsForSearch: Infinity
        })
    };

    document
        .querySelectorAll('.add_item_link')
        .forEach(btn => btn.addEventListener("click", addFormToCollection));

    $(document).on('click','.remove-collection-element', function() {
        $(this).parents('.collection-item').eq(0).remove();
    })

}

window.initScaleForm = () => {
    scaleForm()
}