require('dropify')

export function dropifyInit(selector)
{

    if (!$().dropify()) {
        console.warn('Warning - select2.min.js is not loaded.');
        return
    }

    $(selector).dropify({
        messages: {
            'default': 'Przeciągnij i upuść lub kliknij',
            'replace': 'Przeciągnij i upuść lub kliknij aby zamienić',
            'remove':  'Usuń',
            'error':   'Wystąpił błąd.'
        },
        error: {
            'fileSize': 'The file size is too big ({{ value }} max).',
            'minWidth': 'The image width is too small ({{ value }}}px min).',
            'maxWidth': 'The image width is too big ({{ value }}}px max).',
            'minHeight': 'The image height is too small ({{ value }}}px min).',
            'maxHeight': 'The image height is too big ({{ value }}px max).',
            'imageFormat': 'The image format is not allowed ({{ value }} only).'
        }
    })

}