import { select2Init } from "../functions/select2Init";

const questionForm = () => {

    const addFormToCollection = (e, regex) => {
        const collectionHolder = document.querySelector(e.currentTarget.dataset.collectionHolderClass);

        const item = document.createElement('div');

        item.innerHTML = collectionHolder
            .dataset
            .prototype
            .replace(
                regex,
                collectionHolder.dataset.index
            );

        collectionHolder.appendChild(item);

        collectionHolder.dataset.index++;

        select2Init('.select2')
    };

    $(document).on('click', '.add-area-mail', function (e) {
        addFormToCollection(e, /__area_mail__/g)
    })

    $(document).on('click', '.add-question-mail', function (e) {
        addFormToCollection(e, /__question_mail__/g)
    })

    $(document).on('click', '.add-question-proposed-answer', function (e) {
        addFormToCollection(e, /__question_proposed_answer__/g)
    })

    $(document).on('click', '.add-question-checklist', function (e) {
        addFormToCollection(e, /__question_checklist__/g)
    })

    $(document).on('click','.remove-collection-element', function() {
        $(this).parents('.collection-item').eq(0).remove();
    })

    $(() => {

        select2Init('.select2', {
            minimumResultsForSearch: Infinity
        })

    })

}

window.initQuestionForm = () => {
    questionForm()
}