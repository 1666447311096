export function select2Init(selector, attributes = {})
{

    if (!$().select2) {
        console.warn('Warning - select2.min.js is not loaded.');
        return
    }

    $(selector).select2(attributes)

}