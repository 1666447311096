import {select2Init} from "../functions/select2Init";

const locationForm = () => {

    function zipMasks()
    {
        const inputs = $('[data-mask=postalCode]')

        inputs.each( function () {

            $(this).mask('99-999')

        })
    }

    function phoneMasks()
    {
        const inputs = $('[data-mask=phone]')

        inputs.each( function () {

            $(this).mask('999999999')

        })
    }

    $(() => {

        select2Init('select[data-select=select2]')
        // zipMasks()
        // phoneMasks()

    })
}

window.initLocationForm = () => {
    locationForm()
}