import { select2Init } from "../functions/select2Init";

const planReportSend = () => {

    function condition() {
        const val = $('select[data-bind="condition"] option').filter(':selected').val()

        if (val === '!')
        {
            $('input[data-bind="to-disable"]').prop('disabled', true ).attr('required', false)

        }
        else
        {
            $('input[data-bind="to-disable"]').prop('disabled', false ).attr('required', false)
        }
    }

    $(document).on('change', 'select[data-bind="condition"]', () => {

        condition()
    })

    const addFormToCollection = (e, regex) => {
        const collectionHolder = document.querySelector(e.currentTarget.dataset.collectionHolderClass);

        const item = document.createElement('div');

        item.innerHTML = collectionHolder
            .dataset
            .prototype
            .replace(
                regex,
                collectionHolder.dataset.index
            );

        collectionHolder.appendChild(item);

        collectionHolder.dataset.index++;

        select2Init('.select2')
    };

    $(document).on('click', '.add-condition', function (e) {
        addFormToCollection(e, /_planReportSendType_collectionItem_/g)
    })

    $(document).on('click', '.add-email', function (e) {
        addFormToCollection(e, /_planReportSendItem_mailing_/g)
    })

    $(document).on('click','.remove-collection-element', function() {
        $(this).parents('.collection-item').eq(0).remove();
    })

    $(() => {

        select2Init('.select2', {
            minimumResultsForSearch: Infinity
        })

        condition()

    })
}

window.initPlanReportSend = () => {
    planReportSend()
}