const correctiveActionCard = () => {

    $(document).on('click', '.see-photo', function (e) {
        e.preventDefault()

        const photoId = $(this).data('photo')

        const div = $('#photo')
        const span = div.find('#photo-preview')
        span.remove()

        div.prepend('<span id="photo-preview"><img width="100%" height="100%" src="/uploads/photos/' + photoId + '" alt="Zdjęcie"></span>')

        $('#photo-modal').modal('show')

    })

}

window.initCorrectiveActionCard = () => {
    correctiveActionCard()
}